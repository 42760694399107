@import url("https://fonts.googleapis.com/css?family=Montserrat&display=swap");

html {
  height: 100%;
}

body {
  background: #fafafa !important;
  height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}
